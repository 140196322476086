<template>
  <section class="open_invoice_detail">
    <NavBar />
    <Header type="personalCenter" />
    <div class="content box pt30">
      <div class="current_location">当前位置：发票中心-开票历史-开票详情</div>
      <div class="open_invoice_info mt30">
        <div class="title">开票信息</div>
        <div class="info mt30">
          <div class="mt15">
            开票时间：{{openInvoiceDetail.createTime}}
          </div>
          <div class="mt15">
            发票类型：电子发票
          </div>
          <div class="mt15">
            发票抬头：{{openInvoiceDetail.invTitle}}
          </div>
          <div class="mt15">
            联系人：{{openInvoiceDetail.linkMan}}
          </div>
          <div class="mt15">
            联系电话：{{openInvoiceDetail.linkTel}}
          </div>
          <div class="mt15">
            邮箱地址：{{openInvoiceDetail.cusMail}}
          </div>
          <div class="mt15">
            开票总额：<span>{{openInvoiceDetail.invMoney}}</span>
          </div>
          <div class="mt15" v-if="openInvoiceDetail.invIdNo">
            纳税人识别号：<span>{{openInvoiceDetail.invIdNo}}</span>
          </div>
        </div>
      </div>
      <div class="associated_order mt30">
        <div class="title">关联订单（共包含{{openInvoiceDetail.invNum}}笔订单）</div>
        <div class="list mt30">
          <div class="item mb15 p15 frsbc" v-for="item in associatedOrder" :key="item.orderNo">
            <div class="left">
              <div>
                订单编号：<span>{{item.orderNo}}</span>
              </div>
            </div>
            <div class="right" @click="orderDetail(item)">订单详情</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import NavBar from 'components/NavBar/index.vue';
  import Header from 'components/Header/index.vue';
  import {
    getOpenInvoiceDetail,
    getAssociatedOrder
  } from 'api/personalCenter.js';

  export default {
    components: {
      NavBar,
      Header
    },

    created() {
      this.getOpenInvoiceDetail(this.$route.params.billNo);
      this.getAssociatedOrder(this.$route.params.billNo);
    },

    data() {
      return {
        openInvoiceDetail: {},
        associatedOrder: []
      }
    },

    methods: {
      getOpenInvoiceDetail(billno) {
        getOpenInvoiceDetail(billno).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.openInvoiceDetail = res.data;
        });
      },
      getAssociatedOrder(billNo) {
        getAssociatedOrder({
          orderType: 'online_buy',
          billNo,
          pageIndex: 1,
          pageSize: 100
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.associatedOrder = res.data.records;
        });
      },
      orderDetail(orderDetail) {
        this.$router.replace({
          name: 'orderDetail',
          params: {
            orderNo: orderDetail.orderNo
          }
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .open_invoice_detail {
    .content {
      .current_location {
        font-size: var(--fontSize16);
        color: var(--color000);
        font-weight: 400;
      }
      .open_invoice_info {
        .title {
          font-size: var(--fontSize18);
          color: var(--color000);
          font-weight: bold;
        }
        .info {
          width: 100%;
          border: 1px solid var(--colorccc);
          padding: 0 15px 15px;
          background: var(--colorfbfbfb);
          div {
            font-size: var(--fontSize16);
            color: var(--color000);
            font-weight: 500;
            span {
              color: var(--color0fe1818);
            }
          }
        }
      }
      .associated_order {
        .title {
          font-size: var(--fontSize18);
          color: var(--color000);
          font-weight: bold;
        }
        .list {
          .item {
            width: 100%;
            border: 1px solid var(--colorccc);
            background: var(--colorfff);
            .left {
              div {
                font-size: var(--fontSize16);
                color: var(--color333);
                font-weight: 400;
                span {
                  font-size: var(--fontSize18);
                  color: var(--color000);
                  font-weight: bold;
                }
              }
            }
            .right {
              font-size: var(--fontSize16);
              color: var(--color1890ff);
              font-weight: bold;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
</style>
